<template>
    <div>
        <a-modal :zIndex="10002" v-model:visible="visibleAdvanced" title="选择岗位" @ok="astrengthModal"
            @cancel="cancelAdvance">
            <div class="advancedBox">
                <a-input placeholder="搜索组织名称">
                    <template #prefix>
                        <!-- <SearchOutlined /> -->
                    </template>
                </a-input>
                <div class="strengthModelhead">
                    {{ gData.title }}
                </div>
                <a-radio-group @change="radiochange" v-model:value="radioValue" name="radioGroup">
                    <a-radio style="display: flex;height: 40px;padding-left: 12px;align-items: center;" :value="item.value"
                        v-for="(item, index) in gData.children" :key='index'>{{ item.title }}</a-radio>
                </a-radio-group>
            </div>
        </a-modal>
    </div>
</template>
    
<script>
export default {
    components: {},
    props: {
        visibleAdvanced: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            gData:
            {
                title: '江西长勤企业有限公司',
                key: '0-0',
                children: [
                    {
                        title: '技术部',
                        value: 6,
                    },
                    {
                        title: '运营部',
                        value: 2,
                    },
                ],
            },

            radioValue: '',//单选框选择
            info: '',//选中节点详情
        }
    },
    methods: {
        astrengthModal() {
            this.$emit('strengthchange', this.info)
            this.radioValue = ''
            this.info = ''
        },
        cancelAdvance() {
            this.$emit('cancelstrength')
            this.radioValue = ''
            this.info = ''
        },
        radiochange(e) {
            console.log(e)
            this.gData.children.forEach(item=>{
                if(item.value == e.target.value){
                    this.info = item
                    return false
                }
            })
        }

    }
}
</script>
    
<style lang="less" scoped="scoped">
/deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #fff;
}

.staffAdvancedBox {
    margin-top: 10px;

    .staffBox_header {}

    .staffBox_contain_box {
        display: flex;
        align-items: center;
        margin: 10px 0;
        height: 50px;
        padding: 0 10px;
        cursor: pointer;

        .anticon-folder-open {
            font-size: 30px;
            margin-right: 10px;
        }

        .staffBox_span {
            font-size: 12px;
            color: #80848f;
        }
    }

    .scollStaff {
        height: 360px;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
    }

    .staffBox_contain_checked {
        padding: 0 10px;
        display: flex;
        align-items: center;
        height: 50px;

        /deep/.ant-radio-wrapper {
            display: flex;
            align-items: center;
        }

        .s_b_c_c_box {
            display: flex;
            align-items: center;
            width: 400px;
            position: relative;

            span {
                color: #397dee;
            }
        }

        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 10px;
        }

        .staff_c_c_text {
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .staffBox_contain_checked:hover {
        background-color: #f2f2f2;
    }

    .staffBox_contain_box:hover {
        background-color: #f2f2f2;
    }

}

.strengthModelhead {
    margin: 12px 5px 12px;
    min-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
}
</style>